import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Notes = ({ data, location }) => {
  const isPublic = (e) => !e.node.frontmatter.draft;

  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges.filter(isPublic);

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <section className="page-notes container-reading">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <article className="note" key={node.fields.slug}>
              <header>
                <h3 className="note-title">
                  <Link to={node.fields.slug}>{title}</Link>
                </h3>
                <small className="note-date">{node.frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </section>
            </article>
          );
        })}
      </section>
    </Layout>
  );
};

export default Notes;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            draft
          }
        }
      }
    }
  }
`;
